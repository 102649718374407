import {Injectable} from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthenticationService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let redirectPath = next.data['redirect'] || ['/login'];
    return this.authService.isLoggedIn()
      .pipe(
        take(1),
        map((isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            this.router.navigate(redirectPath);
            return false;
          } else {
            const parseJwt = (text) => {

              const base64Url = text.split('.')[1];
              const base64 = base64Url.replace('-', '+').replace('_', '/');
              return JSON.parse(window.atob(base64));
            };
            const jwt = parseJwt(localStorage.getItem('token'));

            if (jwt.hasOwnProperty('time')) {
              // If token has expired
              if (jwt.time * 1000 < new Date().getTime()) {
                localStorage.clear();
                this.router.navigate(redirectPath);
                return false;
              }
            }
            return true;
          }
        }));

  }


}
