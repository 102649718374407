import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-read-only',
 template: `
        <nz-form-control>
            <nz-input-group [class]="to.className">
                <span>{{formControl.value}}</span>
            </nz-input-group>
        </nz-form-control>
 `,
})
export class FormlyReadOnly extends FieldType{
}
