import {Injectable} from '@angular/core';
import {of, Observable, BehaviorSubject, timer} from 'rxjs';
import {delay, map, share, switchMap, tap, mapTo} from 'rxjs/internal/operators';
import {Router} from '@angular/router';
import {ApiCommands} from '../../../shared/http/api.commands';
import {API} from '../../../shared/config/api.constants';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  authSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.hasToken());
  dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>({type: 'none', data: null});
  dataObject = this.dataSubject.asObservable();

  userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userObject = this.userSubject.asObservable();


  constructor(private apiCommands: ApiCommands, private router: Router) {
  }

  public isLoggedIn(): Observable<boolean> {
    return this.authSubject.asObservable().pipe(share());
  }

  public getUserData(): Observable<any> {
    return this.userSubject.asObservable().pipe(share());
  }

  public login(body: any) {
    return this.apiCommands.post(API.LOGIN.url, body, '').pipe(map(res => res));
  }


  // getUserStatus() {
  //   return this.apiCommands.get(API.USER_STATUS.url, this.getToken())
  //     .pipe(map((res) => {
  //       this.userSubject.next(res);
  //       localStorage.setItem('user', JSON.stringify(res));
  //       return res;
  //     }));
  // }

  // register(body: any): any {
  //     return this.apiCommands.post(API.REGISTER.url, body, '')
  //         .pipe(map(res => res));
  // }

  // checkVerification(body): any {
  //     return this.apiCommands.post(API.CODE_VERIFICATION.url, body, '')
  //         .pipe(map(res => res));
  //
  // }

  asyncLogin(): Observable<number> {
    return of(200).pipe(delay(2000));
  }

  private getToken() {
    return `${localStorage.getItem('token')}`;
  }

  private hasToken(): boolean {
    return !!localStorage.getItem('token');
  }


}
