import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ptTableActionConditionChecker'
})
export class PtTableActionConditionCheckerPipe implements PipeTransform {

  transform(condition: any, ...args: any[]): unknown {
    let data = args[0];
    if(eval(condition)){
      return true
    } else {
      return false
    }
  }
}
