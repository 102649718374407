import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-switch',
 template: `
    <nz-form-control>
        <nz-input-group [nzPrefixIcon]="to.icon" [class]="to.className" nzCompact>
            <div class="pt-form__boxes">
                <div class="pt-form__input pt-form__input--2">{{to.label}}</div>
                <div class="pt-form__input pt-form__input--2">
                    <nz-switch [formControl]="formControl"></nz-switch>
                </div>
            </div>
        </nz-input-group>
    </nz-form-control>
 `,
})
export class FormlySwitch extends FieldType{
}