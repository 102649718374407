import { environment } from './../../../environments/environment';
import {UploadChangeParam, UploadFile} from 'ng-zorro-antd/upload';
import { Component, OnInit } from '@angular/core';
import {FieldType} from '@ngx-formly/core';


@Component({
    selector: 'formly-files-upload',
    template: `
        <nz-form-control>
            <nz-input-group [nzPrefixIcon]="to.icon" [class]="to.className" nzCompact>
                <span>{{to.label}}</span>
                <nz-upload nzListType="picture" [formControl]="formControl" [nzMultiple]="true" [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                    <button nz-button nzType='link'><i nz-icon nzType="upload"></i>Carica allegato</button>
                </nz-upload>
            </nz-input-group>
        </nz-form-control>
    `,
})
export class FormlyMultiFileUpload extends FieldType implements OnInit {
    uploading = false;
    baseUrl = environment.baseUrl;
    fileList: UploadFile[] = [];

    ngOnInit(){
        console.log(this.formControl)
        console.log(this.formControl.value)
        this.formControl.value.map(file => {
            this.fileList.push({uid: file.id, name: file.file_name, url: this.baseUrl + file.path + '/' + file.file_name})
        })
    }

    beforeUpload = (file: UploadFile): boolean => {
        this.fileList = this.fileList.concat(file);
        this.formControl.setValue(this.fileList);
        return false;
    };
}
