import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZorroModule } from './../zorro/zorro.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiCommands } from './http/api.commands';
import { PtTableComponent } from './components/pt-table/pt-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PtFilterComponent } from './components/pt-filter/pt-filter.component';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { FormlyModule } from '@ngx-formly/core';
import { formlyConfig } from '../app.constants';
import { ArrayOfObjectsFilterPipe } from './pipes/array-of-objects-filter.pipe';
import { PermissionDirective } from './directives/permission.directive';
import { PtTableActionConditionCheckerPipe } from './pipes/pt-table-action-condition-checker.pipe';


@NgModule({
    declarations: [PtTableComponent, PtFilterComponent, ArrayOfObjectsFilterPipe, PermissionDirective, PtTableActionConditionCheckerPipe],
    imports: [
        CommonModule,
        ZorroModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        FormlyNgZorroAntdModule,
        FormlyModule.forRoot(formlyConfig),
        NgSelectModule,
        RouterModule

    ],
    exports: [PtTableComponent, PtFilterComponent, ArrayOfObjectsFilterPipe, PermissionDirective],
    providers: [ApiCommands]
})
export class SharedModule {
}
