<div>
    <nz-drawer [nzVisible]="filterVisibility" [nzMask]="filterVisibility" nzPlacement="right" nzTitle="Filtri" (nzOnClose)="closeFilter()">
        <form [formGroup]="form" (ngSubmit)="submit(model)">
            <formly-form [model]="model" [form]="form" [fields]="filterFields">
            </formly-form>
            <div nz-row nzJustify="end" class="w-100">
                <!-- <button nz-col nz-button  (click)="closeFilter()" [nzType]="'primary'">Filtro</button>
                <button nz-col nz-button  (click)="reset()" [nzType]="'primary'">Reset</button> -->
                <nz-divider></nz-divider>
      
                <button nz-button nzType="primary" type="submit" (click)="closeFilter()" nzBlock >Applica</button>
                <a nz-button nzType="link" type="reset" (click)="reset()" nzBlock>Reset filtri</a>
            </div>
        </form>
    </nz-drawer>

</div>
