import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayOfObjectsFilter'
})
export class ArrayOfObjectsFilterPipe implements PipeTransform {

  transform(value: any[], ...args: any[]): any {
    let finalValue: boolean = false;
    value.some(val => {
      if(val.user_id == args[0]) {
        finalValue = true;
      } 
      else {
        finalValue = false;
      }
    }) 
    return finalValue;
  }
}
