import { logging } from 'protractor';
/**
 * @author F.Tandoi
 * @description This model provide a Factory to create Webservices Endpoint
 * with all informations about the call. It is used in API_CONSTANTS file
 */
import {environment} from '../../../environments/environment';



export class Param {
    name: string;
    value: any;

    constructor(name: string, value: any) {
        this.name = name;
        this.value = value;
    }

    public static create(name: string, value: any): Param {
        return new Param(name, value);
    }

}

export class Endpoint {

    private baseUrl = environment.baseUrl;

    auth: string;
    url: string;
    private path: string;

    constructor(path: string, auth = 'Basic') {
        this.path = path;
        this.url = this.buildPath(path);
        this.auth = auth;

    }

    public static create(path: string, auth?: string, params?: Param[]): Endpoint {
        return new Endpoint(path, auth);
    }

    private buildPath(path: string): string {
        return `${this.baseUrl}/${path}`;
    }

    setParam(val: string) {
        let newPath = '';
        if (this.path.indexOf('{') !== -1) {
            newPath = this.path.replace(this.path.substring(this.path.indexOf('{'), this.path.indexOf('}') + 1), val);
        }
        return new Endpoint(newPath, this.auth);
    }



}

