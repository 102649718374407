import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-multiselect-checkbox',
 template: `
        <nz-form-control>
            <nz-input-group [class]="to.className">
                <ng-select
                    [items]="to.options"
                    [multiple]="true"
                    bindLabel="label"
                    showSelected="false"
                    [closeOnSelect]="false"
                    bindValue="value"
                    [formControl]="formControl"
                    [placeholder]="to.placeholder">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.label}}
                    </ng-template>
                </ng-select>
            </nz-input-group>
        </nz-form-control>
 `,
})

export class FormlyMultiselectCheckbox extends FieldType{}
