import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[checkPermission]'
})
export class PermissionDirective implements OnInit{
  @Input() type:string = "two";

  constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit(){
        console.log(this.el.nativeElement)

      if(this.type != 'admin'){
        console.log(this.type)
        console.log(this.el.nativeElement)
        this.renderer.removeChild(this.el.nativeElement, this.el.nativeElement)
      }
  }
}

// <p checkPermission type="addmin">
// test test test
// </p>