import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-ck-editor',
 template: `
    <nz-form-control>
        <nz-input-group [nzPrefixIcon]="to.icon" [class]="to.className" nzCompact>
            <ckeditor style="width:100%;" data="<p>Hello, world!</p>" [config]="config" [formControl]="formControl"></ckeditor>
        </nz-input-group>
    </nz-form-control>
 `,
})
export class FormlyCkEditor extends FieldType{
    config = {
        language: 'it',
        enterMode: 2
    }
}
