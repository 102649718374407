import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-password',
 template: `
        <nz-form-control>
            <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate" [class]="to.className">
                <input [type]="passwordVisible ? 'text' : 'password'" nz-input [formControl]="formControl" [formlyAttributes]="field" />
            </nz-input-group>
            <ng-template #suffixTemplate>
                <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
            </ng-template>
        </nz-form-control>
 `,
})
export class FormlyPassword extends FieldType{
    passwordVisible = false;
    password?: string;
}
