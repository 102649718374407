import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-text-input-pre-section',
 template: `
    <nz-form-control>
            <nz-input-group [nzAddOnBefore]="to.pre_section_text">
                <input nz-input name="url" type="text" id="url" minlength="1" [formControl]="formControl" [formlyAttributes]="field" />
            </nz-input-group>
    </nz-form-control>
 `,
})
export class FormlyTextInputPreSection extends FieldType{
}   