import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-multicheckbox",
  template: `
    <nz-form-control>
      <nz-input-group [nzPrefixIcon]="to.icon" nzCompact class="pt-form__boxes">
        <label
          *ngFor="let option of to.options"
          nz-checkbox
          [nzChecked]="setOfCheckedId.has(option.value)"
          (nzCheckedChange)="onItemChecked(option.value, $event)"
          class="pt-form__input multi-checkbox">
          {{ option.label }}
        </label>
      </nz-input-group>
    </nz-form-control>
  `,
})
export class FormlyMulticheckbox extends FieldType implements OnInit {
  setOfCheckedId = new Set<any>();

  ngOnInit() {
    this.formControl.setValue([])
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.formControl.setValue(Array.from(this.setOfCheckedId.values()));
  }
}
