<nz-table 
  #table 
  [nzData]="tableData" 
  #headerTable  
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
  [nzShowPagination]="enablePagination" 
  [nzPageSize]="pageSize" 
  [nzScroll]="nzScroll"  
  [nzFrontPagination]="frontendPagination"
  [nzTotal]="total"
  [nzPageIndex]="pageIndex"
  (nzPageIndexChange)="onPageIndexChange($event)"
  >
  <!-- (nzQueryParams)="onQueryParamsChange($event)" -->
    <thead (nzSortChange)="sort($event)" nzSingleSort>
      <tr     
        cdkDropList 
        cdkDropListOrientation="horizontal" 
        (cdkDropListDropped)="drop($event)">
        <th *ngIf="enableRowSelect"
            [(nzChecked)]="checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
            nzWidth='120px'
        ></th>
        <!-- [nzSortOrder]="column.sortOrder"
        [nzSortFn]="column.sortFn"
        [nzSortDirections]="column.sortDirections"
        (nzSortOrderChange)="onSortOrderChange($event, column.name)" -->
        <th
          *ngFor="let column of tableSchema.fields"
      
          class="draggable-column" 
          cdkDrag 
          cdkDragLockAxis="x"
          nzShowSort [nzSortKey]="column.name"
          (nzSortOrderChange)="onSortOrderChange($event, column.name)"
          >
          {{column.label}}
        </th>
        <th *ngIf="tableSchema.actions && tableSchema.actions.length > 0" nzWidth='120px'>
            Azioni
        </th>
        <th *ngIf="tableSchema.buttons && tableSchema.buttons.length > 0"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of table.data">
        <ng-container *ngIf="tableSchema.conditions && tableSchema.conditions.row_select ? (tableSchema.conditions.row_select | ptTableActionConditionChecker: data) : true; else disabledCheckbox">
          <td *ngIf="enableRowSelect" [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
        </ng-container>
        <ng-template #disabledCheckbox>
          <td [nzChecked]="false" nzDisabled></td>
        </ng-template>
        <ng-container *ngFor="let column of dataOrder">
          <!-- <td *ngIf="column.type == 'text' || column.type === 'email' || column.type === 'partitaiva' || column.type === 'codicefiscale' || column.type === 'textarea' || column.type === 'ckeditor' || column.type === 'number'"> -->
          <td *ngIf="column.type == 'text'">
            {{data[column.name]}}
          </td>    

          <td *ngIf="column.type == 'date'">
            {{data[column.name] | date:'dd/MM/y'}}
          </td>

          <td *ngIf="column.type == 'datetime'">
            {{data[column.name] | date:'short'}}
          </td>

          <td *ngIf="column.type == 'image'">
            <div class="table-image">
              <img [src]="data[column.name]" alt="">
            </div>
          </td>

          <td *ngIf="column.type == 'link'">
            <a [href]="data[column.name]" target="_blank">Visualizza</a>
          </td>

          <td *ngIf="column.type == 'navigate'">
            <ng-container *ngIf="column.condition ? (column.condition | ptTableActionConditionChecker: data) : true; else elseTemplate">
              <a nz-button nzType="link" [routerLink]="[column.link + data[column.data_field]]" [queryParams]="column.queryParams ? column.queryParams : ''">{{data[column.name]}}</a>
            </ng-container>
            <ng-template #elseTemplate>
              <a [href]="data[column.url]" target="_blank">{{data[column.name]}}</a>
            </ng-template>
          </td>

          <td *ngIf="column.type == 'password'">
            ********
          </td>

        </ng-container>
        <td *ngIf="tableSchema.actions && tableSchema.actions.length > 0">
          <a nz-dropdown [nzPlacement]="'bottomCenter'" nzTrigger="click" [nzDropdownMenu]="actions"><img src="assets/images/icon-dots.png" alt="actions" class="dot-icon"></a>
          <nz-dropdown-menu #actions="nzDropdownMenu">
              <ul nz-menu>
                <ng-container *ngFor="let action of tableSchema.actions" >
                  <li 
                    *ngIf="action.condition ? (action.condition | ptTableActionConditionChecker: data) : true"
                    nz-menu-item 
                    (click)="getAction(action.handler, data.id, data)">
                    {{action.tooltip}}
                </li>
                </ng-container>
              </ul>
          </nz-dropdown-menu>
        </td>       
        <td *ngIf="tableSchema.buttons && tableSchema.buttons.length > 0">
          <ng-container *ngFor="let button of tableSchema.buttons" >
            <span (click)="getAction(button, data.id, data)" class="pt-table-button">
              {{button}}
            </span>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </nz-table>
  