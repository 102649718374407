import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-text-input',
 template: `
    <nz-form-control>
        <nz-input-group [nzPrefixIcon]="to.icon" [class]="to.className" nzCompact>
            <input type="text" nz-input [formControl]="formControl" [formlyAttributes]="field" />
        </nz-input-group>
    </nz-form-control>
 `,
})
export class FormlyTextInput extends FieldType{
}