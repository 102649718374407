import { NgModule } from '@angular/core';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSpaceModule } from 'ng-zorro-antd/space';



//* imports zorro
import { NgZorroAntdModule } from 'ng-zorro-antd';


@NgModule({
  declarations: [],
  exports: [
    NzTreeSelectModule,
    NzTreeModule,
    NzIconModule,
    NgZorroAntdModule,
    NzButtonModule,
    NzGridModule,
    NzDropDownModule,
    NzAvatarModule,
    NzCardModule,
    NzLayoutModule,
    NzMenuModule,
    NzBreadCrumbModule,
    NzDividerModule,
    NzDescriptionsModule,
    NzUploadModule,
    NzTabsModule,
    NzDrawerModule,
    NzStepsModule,
    NzModalModule,
    NzMessageModule,
    NzPaginationModule,
    NzTableModule,
    NzSwitchModule,
    NzFormModule,
    NzCarouselModule,
    NzListModule,
    NzCommentModule,
    NzInputModule,
    NzAffixModule, 
    NzSelectModule,
    NzDatePickerModule,
    NzCheckboxModule,
    NzSpaceModule,
    





    
  ]
})
export class ZorroModule { }