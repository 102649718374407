import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-two-field-input',
 template: `
    <nz-form-control>
        <nz-input-group class="two-field-input-container">
            <input type="text" nz-input [formControl]="formControl.get('firstInput')" [placeholder]="to.firstFieldPlaceholder" class="two-field-input__first-input" />
            <div class="two-field-input__separater"></div>
            <input type="text" nz-input [formControl]="formControl.get('secondInput')" [placeholder]="to.secondFieldPlaceholder" class="two-field-input__second-input" />  
        </nz-input-group>
    </nz-form-control>
 `,
})
        // [formControl]="formControl" [formlyAttributes]="field"

export class FormlyTwoFieldInput extends FieldType{
}
