import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NzTableQueryParams } from 'ng-zorro-antd';

@Component({
  selector: 'pt-table',
  templateUrl: './pt-table.component.html',
  styleUrls: ['./pt-table.component.css']
})

export class PtTableComponent implements OnInit {

  constructor() { 
  }

  @Input() tableData: any;
  @Input() tableSchema: any; 
  @Input() pageSize: any = '10';
  @Input() enablePagination: boolean = true;
  @Input() tableHeight: any = '650px';
  // @Input() tableWidth: any = '1000px'
  @Output() tableActionEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Input() enableRowSelect: boolean = false;
  data: any;

  sortOrder: boolean = false;
  listOfCurrentPageData: any = [];
  dataOrder: any = [];
  test: any = [];
  nzScroll: any = {};

  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();

  // backend pagination state
  @Input() frontendPagination = true;
  @Input() total = 1;
  @Input() pageIndex = 1;
  @Output() onQueryParamsChangeEmitter: EventEmitter<any> = new EventEmitter();



  ngOnInit(): void {
    this.tableSchema.fields.forEach(e => {
      e.sortOrder = null;      
      e.sortFn = (a, b) => {
        if(a[e.name] && b[e.name]){
          return a[e.name].localeCompare(b[e.name])
        }
      }
    })
    this.nzScroll.y = this.tableHeight;
    this.getHeaderOrder();
  }

  setOrderField(field) {
    let type = '';
    this.sortOrder = !this.sortOrder;
    this.tableData = this.tableData.map(groups => {
      if (parseInt(groups[field])) {
        groups[field] = parseInt(groups[field]);
      }
      type = typeof groups[field];
      return groups;
    }).sort((a, b) => {
      if (type === 'number') {
        return (this.sortOrder ? a[field] - b[field] : b[field] - a[field]);
      } else if (type != 'number') {
        let x = (typeof a[field] === 'string') ? a[field].toUpperCase() : a[field];
        let y = (typeof b[field] === 'string') ? b[field].toUpperCase() : b[field];
        if (x < y) {
          return (this.sortOrder ? -1 : 1);
        } else {
          return (this.sortOrder ? 1 : -1);
        }
      }
    });
  }

  onSortOrderChange(params, col){
    // this.setOrderField(col);
  }

  getAction(handler, id, field){
    this.tableActionEvent.emit({handler, id, field})
  }

  onCurrentPageDataChange(listOfCurrentPageData): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    // this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tableSchema.fields, event.previousIndex, event.currentIndex);
    this.dataOrder = [];
    this.getHeaderOrder();
  }

  getHeaderOrder() {
    this.tableSchema.fields.map(data => this.dataOrder.push(data));
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
    this.rowSelectEmitter.emit(Array.from(this.setOfCheckedId.values()))
    
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
    this.rowSelectEmitter.emit(Array.from(this.setOfCheckedId.values()))
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  // onQueryParamsChange(params: NzTableQueryParams){
  //   this.onQueryParamsChangeEmitter.emit(params);
  //   console.log(params)
  // }

  sortName = null;
  sortValue = null;

  sort(sort: { key: string, value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }

  search(): void {
    /** sort data **/
    this.data = this.tableData;
    if (this.sortName) {
      const data = this.data.sort((a, b) => {
        if(a[this.sortName] && b[this.sortName]){
          return (this.sortValue === 'ascend') ? 
                  (a[ this.sortName ].toString() > b[ this.sortName ].toString() ? 1 : -1) : 
                  (b[ this.sortName ].toString() > a[ this.sortName ].toString() ? 1 : -1)
        }
      })
      this.tableData = data;
    } else {
      this.tableData = this.data;
    }
  }

  onPageIndexChange(event: NzTableQueryParams){
    this.onQueryParamsChangeEmitter.emit(event);
  }
}