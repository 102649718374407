import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-multiselect',
 template: `
    <nz-form-control>
        <nz-input-group [nzPrefixIcon]="to.icon" [class]="to.className" nzCompact>
            <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="to.placeholder" nzMode="multiple" style="width: 50%;" [formControl]="formControl">
                <nz-option [nzLabel]="option.label" [nzValue]="option.value" *ngFor="let option of to.options"></nz-option>
            </nz-select>
        </nz-input-group>
    </nz-form-control>
 `,
})
export class FormlyMultiselect extends FieldType{
}


